import React from "react"
// @ts-ignore
import * as layoutStyles from "../styles/layout.module.css"
import {Link} from "gatsby";
import {motion} from "framer-motion";
import PageViewedContext from "../components/PageViewedContext";
import {OutboundLink} from "gatsby-plugin-google-gtag";

interface Props {
    uri: string;
}

export default function Menu({uri}: Props) {
    // if (uri === "/") {
    //
    // } else {
    //     return (
    //
    //
    //     )
    // }

    const text = {
        visible: {
            opacity: 1, transition: {
                duration: 0.8,
                delay: 4.7,
            }
        },
        hidden: {
            opacity: 0, transition: {
                duration: 0.1
            }
        },
    }

    const textViewed = {
        visible: {
            opacity: 1, transition: {
                duration: 0.2
            }
        },
        hidden: {
            opacity: 0, transition: {
                duration: 0.1
            }
        },
    }

    const lineViewed = {
        visible: {width: "8em"},
        hidden: {width: "0"},
        exit: {clipPath: "inset(0 0 0 100%)"},
    }

    const line = {
        visible: {width: "8em", transition: {
                delay: 4.85,
            }},
        hidden: {width: "0"},
        exit: {clipPath: "inset(0 0 0 100%)"},
    }

    return (
        <PageViewedContext.Consumer>

            {(viewed) => (
                <motion.div
                    initial={"hidden"}
                    animate={"visible"}
                    exit={"hidden"}
                    variants={viewed ? textViewed : text}
                    className={layoutStyles.menu}
                >
                    {uri !== "/" ?
                        <Link to={"../"}>
                            Back
                            <motion.hr initial={"hidden"}
                                       animate={"visible"}
                                       exit={"exit"}
                                       variants={lineViewed}/>
                        </Link>
                        :
                        <>
                            <Link to={"/projects"}>
                                Projects
                                <motion.hr initial={"hidden"}
                                           animate={"visible"}
                                           exit={"exit"}
                                           variants={viewed ? lineViewed : line}/>
                            </Link>
                            <OutboundLink href={"https://www.linkedin.com/in/lawta/"} target={"_blank"}>
                                LinkedIn
                                <motion.hr initial={"hidden"}
                                           animate={"visible"}
                                           exit={"exit"}
                                           variants={viewed ? lineViewed : line}/>
                            </OutboundLink>
                            <OutboundLink href={"https://github.com/lawtancool"} target={"_blank"}>
                                GitHub
                                <motion.hr initial={"hidden"}
                                           animate={"visible"}
                                           exit={"exit"}
                                           variants={viewed ? lineViewed : line}/>
                            </OutboundLink>
                            <OutboundLink href={"/Lawrence Tan Resume.pdf"} target={"_blank"}>
                                Resumé
                                <motion.hr initial={"hidden"}
                                           animate={"visible"}
                                           exit={"exit"}
                                           variants={viewed ? lineViewed : line}/>
                            </OutboundLink>
                        </>
                    }
                </motion.div>
            )}
        </PageViewedContext.Consumer>
    )


}