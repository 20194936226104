import React from "react";

const PageViewedContext = React.createContext(false);

export default PageViewedContext;

/**
 * Context that allows child components to add markers to the map.
 * @param children child components inside this context.
 */
// // @ts-ignore
// export function MarkerProvider({ children }) {
//     const [coords, setCoordsState] = useState([...Array<Coordinate>(2)]);
//
//     const setCoords = (coords: Coordinate[]) => {
//         setCoordsState(coords);
//     };
//
//     return (
//         <PageViewedContext.Provider
//             value={{
//                 coords,
//                 setCoords,
//             }}>
//             {children}
//         </PageViewedContext.Provider>
//     );
// }